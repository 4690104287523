<script setup lang="ts">
/* Computed variables */

/**
 * The current partner.
 */
const partner: ComputedRef<Partner> = computed((): Partner => (useCommonStore().partner));
</script>

<template>
    <main>
        <div class="left-column">
            <img id="partner-logo" :alt="partner.name + ' logo'" :src="partner.logo_url"/>

            <div class="content">
                <slot/>
            </div>
        </div>

        <div class="right-column">
            <img :src="cloudinaryUrl('art/login-page.svg')" alt="Welcome art"
                 class="welcome-art"/>
        </div>
    </main>
</template>

<style scoped>
    main {
        display: flex;
        flex-direction: row;
        height: 100vh;
    }

    .left-column {
        display: grid;
        grid-template-rows: 1fr auto 1fr;
        width: 40rem;
        padding: 4rem 8rem;
        border-radius: 0 2.5rem 2.5rem 0;
        background-color: #FFF;
    }

    #partner-logo {
        height: 2.2rem;
    }

    .content{
        display: flex;
        flex-direction: column;
        gap: 3rem;
    }

    .right-column {
        display: flex;
        flex-grow: 1;
        align-items: center;
        justify-content: center;
    }

    .welcome-art {
        width: 25rem;
    }
</style>

<style>
    .external-form {
        display: flex;
        flex-direction: column;
        gap: 3rem;
    }

    .external-heading {
        color: var(--grey-300);
        font-size: 2rem;
        font-weight: 600;
        line-height: 2.5rem;
    }

    .external-input-fields {
        display: flex;
        flex-direction: column;
        gap: 1.5rem;
    }

    .external-button-group {
        display: flex;
        flex-direction: column;
        gap: 1.5rem;
    }

    .external-button-error-group {
        display: flex;
        flex-direction: column;
        gap: 0.5rem;
    }

    .incorrect-text {
        height: 1.5rem;
        color: var(--danger-500);
    }
</style>
